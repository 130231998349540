(function () {
	on(window, 'load', function () {
		// Start
		$(document).foundation();
	});
})();

import { on, off, throttle, isInViewport } from './utils/_fn';

(function (window, document) {
    /* ---------------------------------------- [START] Windows Setting */
	const html = document.documentElement;
	const body = document.body;
	let ww = window.innerWidth;
	let wh = window.innerHeight;
	let ws = 0;
	function getScrollTop(target = window) {
		return (target.pageYOffset || html.scrollTop) - (html.clientTop || 0);
	}
	function getWinSet() {
		ww = window.innerWidth;
		wh = window.innerHeight;
		ws = getScrollTop();
	}
	on(window, 'load', getWinSet);
	on(window, 'resize', throttle(getWinSet, 50, 100));

	/* ---------------------------------------- [START] 整頁 Scroll 監測 (Before) */
	var pageScrollCheckList = []; // 紀錄物件
	var pageScrollAdd = function (selector, buildFunction) {
		var el = document.querySelector(selector);
		if (el === null) {
			return false;
		}
		pageScrollCheckList.push({
			build: false,
			el: el,
			fun: buildFunction,
		});
	};
	var pageScrollClear = function () {
		pageScrollCheckList = Array.prototype.filter.call(pageScrollCheckList, function (item) {
			return item.build === false;
		});
	};

	/* ----------------------------------- [START] Set YT */
	$('.ytvideo[data-video]').on('click', function() {
		$('.yt_outline').delay(500).fadeOut(4000);
		$('.yt__deco-leaf').delay(500).fadeOut(100);
		$(this).html('<iframe src="//www.youtube.com/embed/'+$(this).data("video")+'?rel=0;&autoplay=1&mute=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>');
	});
	/* ----------------------------------- [START]filter */
	function TagFilter(){
		// 初始化，顯示全部的項目
		$('.store__item').fadeIn();

		// 點擊按鈕時觸發事件
		$('a[data-filter]').click(function() {
			// 移除所有按鈕的 is-active 樣式
			$('a[data-filter]').removeClass('is-active');
			// 添加點擊的按鈕的 is-active 樣式
			$(this).addClass('is-active');

			// 獲取按鈕的 data-filter 值
			var filter = $(this).data('filter');

			// 隱藏所有項目，並以動畫效果淡出
			$('.store__item').fadeOut();

			// 顯示符合篩選條件的項目，並以動畫效果淡入
			if (filter === 'all') {
			  $('.store__item').fadeIn();
			} else {
			  $('.store__item[data-filter-cat="' + filter + '"]').fadeIn();
			}
		});
	}
	TagFilter();
	/* ---------------------------------------- [START]  reports swiper */
	// const reportsCarousel = '#reports-carousel';
	// function reportsBuild() {
	// 	const swiperTarget = reportsCarousel;
	// 	const swiperEl = document.querySelector(swiperTarget).parentElement;
	// 	const swiperNextEl = swiperEl.querySelector('.custom-next-button');
	// 	const swiperPrevEl = swiperEl.querySelector('.custom-prev-button');

	// 	const swiper = new Swiper(swiperTarget, {
	// 		slidesPerView: 2,
	// 		spaceBetween: 20,
	// 		loop: true,
	// 		navigation: {
	// 			nextEl: swiperNextEl,
	// 			prevEl: swiperPrevEl,
	// 		},
	// 		breakpoints: {
	// 			// >= 640px
	// 			1024: {
	// 				slidesPerView: 4,
	// 				slidesPerGroup: 4,
	// 				spaceBetween: 35,
	// 			}
	// 		}
	// 	});
	// }
	// on(window, 'load', function () {
	// 	pageScrollAdd(reportsCarousel, reportsBuild);
	// });

	/* ---------------------------------------- [START] //設定彈窗顯示 */
	// var popup = new Foundation.Reveal($('#ask'));
	// popup.open();

	// popup.$element.on('closed.zf.reveal', function() {
	// 	$('body').css('overflow-y', 'auto');
	// });

	/* ---------------------------------------- [START] ＧＳＡＰ動態 */
	// 有滑動到.sec 的中間區增加動態者增加class "js-ani" ， 滑動到該區增加class "is-animated"
	gsap.registerPlugin(ScrollTrigger);
	var aki = $('.aki');
	var aki2 = $('.aki2');
	var factsContainerAki = aki.find('.factsContainer_sm');
	var factsContainerAki2 = aki2.find('.factsContainer_sm_2');

	// 設定左側padding
	var con = $('.yt').find('.yt__con');
	var containerWidth = con.width();
	var padding = (ww - containerWidth) / 2;

	aki.css('padding-left', padding + 'px');
	aki2.css('padding-right', padding + 'px');
	var containerWidth = factsContainerAki.width();
	var containerWidthAki2 = factsContainerAki2.width();
	var akiImgPc = $('.aki__img-large').width();
	var akiImgPc2 = $('.aki2__img-large').width();
	var containerWidthAki_2 = akiImgPc;
	var containerWidthAki2_2 = akiImgPc2;
	
	// 調酒1
	function akiAnimationPC() {
		var offset = 0; // Offset from right side in viewport width (vw)
		var startPosition = containerWidthAki_2 - (ww * (offset / 100));
		// // Set the initial x position of the animation to startPosition
		// gsap.set('.factsContainer_sm', { x: startPosition });
		// 水平滾動動畫
		gsap.to('.factsContainer_sm', {
			x: -startPosition, // 向左滾動
			ease: 'none', // 滾動速度
			scrollTrigger: {
				trigger: aki, // 當.aki進入視窗
				start: 'center center', // 開始動畫位置
				end: 'bottom center', // 結束動畫位置
				scrub: true, // 跟隨滾動位置
				pin: true,
				// markers: true,
				onEnter: function () {
					aki.addClass('is-animated'); 
				},
			},
		});

		console.log(akiImgPc);
	}
	function akiAnimationMobile() {
		gsap.to('.factsContainer_sm', {
			scrollTrigger: {
			  trigger: aki,
			  start: 'top center',
			  end: 'center center',
			  scrub: true,
			  onEnter: function () {
				aki.addClass('is-animated');
			  },
			},
		});
	}
	// 調酒2
	function akiAnimationPC2() {
		var offset = 10; // Offset from right side in viewport width (vw)
		// Calculate the horizontal position to start the animation
		var startPosition = containerWidthAki2_2 + (ww * (offset / 100));
	
		// Set the initial x position of the animation to startPosition
		gsap.set('.factsContainer_sm_2', { x: -startPosition });
	
		// Horizontal scrolling animation
		gsap.to('.factsContainer_sm_2', {
			x: 0, // Move to the right
			ease: 'none', // Smooth scrolling effect
			scrollTrigger: {
				trigger: aki2, // Trigger the animation when .aki2 section comes into view
				start: 'center center', // Start animation when .aki2 is in the center of the viewport
				end: 'bottom center', // End animation when .aki2 is at the bottom center of the viewport
				scrub: true, // Synchronize the animation with the scrollbar
				pin: true,
				// markers: true, // Uncomment for debugging
				onEnter: function () {
					aki2.addClass('is-animated');
				},
			},
		});
	
		console.log(containerWidthAki2);
	}
	
	function akiAnimationMobile2() {
		gsap.to('.factsContainer_sm_2', {
			scrollTrigger: {
			  trigger: aki2,
			  start: 'top center',
			  end: 'center center',
			  scrub: true,
			  onEnter: function () {
				aki2.addClass('is-animated');
			  },
			},
		});
	}
	
	// 全區動畫
	function mainAnimation() {
		let aniSec = document.querySelectorAll('.js-ani');
		const elements = [
			{ element: '.banner', start: 'top center', end: 'bottom center'},
			{ element: '.culture', start: 'center center', end: 'bottom center'},
			{ element: '.yt', start: 'center center', end: 'bottom center'},
			{ element: '.sec_media', start: 'center center', end: 'bottom center'},
			{ element: '.store', start: 'top center', end: 'bottom center'}
		];
		elements.forEach((item, index) => {
			const element = document.querySelector(item.element);
			const trigger = element.closest('.sec');
			gsap.to(element, {
				scrollTrigger: {
					trigger: trigger,
					start: item.start,
					end: item.end,
					// markers: true, 
					onEnter: () => {
						element.classList.add('is-animated');
						if (index < elements.length - 1) {
							gsap.to(elements[index + 1].element, {
								scrollTrigger: {
									trigger: elements[index + 1].element,
									start: 'top center',
									end: 'bottom center',
									// markers: true,
									onEnter: () => {
										document.querySelector(elements[index + 1].element).classList.add('is-animated');
									},
								},
							});
						}
					},
				},
			});
		});
		function cleanTrigger() {
			aniSec = Array.prototype.filter.call(aniSec, function (ani) {
				return !ani.classList.contains('is-animated');
			});
		}
	}
	
	// 警語彈窗關閉
	// $('#yea_btn').on('click', function () {
	// 	popup.close();
	// });
	
	// popup.$element.on('closed.zf.reveal', function() {
		
	// 	if (ww >= 1024) {
	// 		akiAnimationPC();
	// 		// akiAnimationPC2();
	// 	}else{
	// 		akiAnimationMobile();
	// 		// akiAnimationMobile2();
	// 	}
	// 	mainAnimation();
	// });

	//暫時關閉用動畫
	if (ww >= 1024) {
		akiAnimationPC();
		akiAnimationPC2();
	}else{
		akiAnimationMobile();
		akiAnimationMobile2();
	}	
	mainAnimation();
	

	/* ---------------------------------------- [START] 整頁 Scroll 監測 (After) */
	var pageScrollThrottle = null;
	var pageScrollHandler = function () {
		for (var i = 0; i < pageScrollCheckList.length; i++) {
			if (isInViewport(pageScrollCheckList[i].el)) {
				pageScrollCheckList[i].fun();
				pageScrollCheckList[i].build = true;
			}
		}

		// Clear
		pageScrollClear();

		// Stop
		if (pageScrollCheckList.length === 0) {
			off(window, 'scroll', pageScrollThrottle);
		}
	};

	on(window, 'load', function() {
		pageScrollThrottle = throttle(pageScrollHandler, 50, 1000); // 節流作用
		on(window, 'scroll', pageScrollThrottle);
		pageScrollHandler();
	});
})(window, document);

/* ----------------------------------- [START] set popup title resize */
function popupTitle($modal) {
	var $title = $modal.find('.store_popup__con-title');
	var titleText = $title.text();

	if (window.matchMedia("(min-width: 768px)").matches) {
		var screenWidth = $(window).width();
		var titleLength = titleText.length;

		// 如標題小於十個字設定字體大小填滿螢幕寬度80%
		if (titleLength > 10) {
			var fontSize = (screenWidth * 0.8) / titleLength;
			$title.css('font-size', fontSize + 'px');
		}
	}

	var $imgTop = $modal.find('.store_popup__img');
	var $titleHeight = $title.innerHeight();
	var titleHeightHalf = $titleHeight / 2;
	$imgTop.css('margin-top', '-' + titleHeightHalf + 'px');
	
	console.log('title height: ' + $titleHeight);
}

$(document).on('open.zf.reveal', '.store_popup', function () {
  var $modal = $(this);
  popupTitle($modal);
});



